import {
  IPromoCampaignFormData,
  IPromoCampaignEnabledFields
} from '@/api/schema';
import { PropType } from 'vue';

import * as vr from '@/helpers/validation';
import {
  ETabKey,
  EFieldTypeKey,
  defaultFormData,
  defaultEnabledFields
} from '@/views/PromoView/PromoCampaignDialog/PromoCampaignFormConfiguration';

export default {
  props: {
    currentTabKey: {
      type: String,
      default: ''
    },
    tabKeysList: {
      type: Array as PropType<Array<string>>,
      default: (): any => []
    },
    formData: {
      type: Object as PropType<IPromoCampaignFormData>,
      default: (): any => defaultFormData
    },
    enabledFields: {
      type: Object as PropType<IPromoCampaignEnabledFields>,
      default: (): any => defaultEnabledFields
    },
    value: {
      type: Boolean,
      default: false
    },
    isViewMode: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      ETabKey,
      EFieldTypeKey
    };
  },

  computed: {
    positiveNumberRules(): any {
      return [
        vr.required,
        (v: string) => vr.validNumber(v, 'Value is not valid'),
        vr.positiveNumber
      ];
    }
  },
  methods: {
    updateFormData(field: string, value: string): void {
      this.$emit('update-form-data', { [field]: value });
    },
    updateEnabledField(field: string, value: string): void {
      this.$emit('update-enabled-field', { [field]: value });
    }
  }
};
