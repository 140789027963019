













































enum ETimeKey {
  HH = 'hh',
  MM = 'mm'
}

const initValue = '';

const createDataContext = (
  name: ETimeKey,
  maxNum: number = 2
): {
  get(): string;
  set(val: string): void;
} => ({
  get(): string {
    return this[name];
  },
  set(val: string): void {
    const length = typeof val === 'string' ? val.length : 0;
    const isMin = name === ETimeKey.MM;

    let prepared = length ? (+val).toFixed(0) : '0';

    if (isMin) {
      if (+prepared >= 60) {
        const increase = Math.floor(+prepared / 60);

        prepared = `${+prepared % 60}`;
        this[`computed${ETimeKey.HH.toUpperCase()}`] = `${
          +this[ETimeKey.HH] + increase
        }`;
      }
    } else {
      if (prepared.length > 1) {
        prepared = prepared.replace(/^0+/, '');
      }
    }

    if (prepared.length > maxNum) {
      prepared = val.slice(-maxNum);
    }

    if (prepared !== val) {
      if (
        (val.length > 1 && [...new Set([...val])].length === 1) ||
        (!isMin && val.startsWith('0')) ||
        val.length > 2
      ) {
        this.update[name] = Date.now();
      }
    }

    if (isMin) {
      prepared = prepared.padStart(maxNum, '0');
    }

    this[name] = prepared;
  }
});

export default {
  name: 'DurationPicker',
  props: {
    value: {
      type: [String, Number]
    }
  },
  data(): {
    [ETimeKey.MM]: string;
    [ETimeKey.HH]: string;
    update: Record<ETimeKey, number>;
  } {
    return {
      [ETimeKey.MM]: initValue,
      [ETimeKey.HH]: initValue,
      update: {
        [ETimeKey.MM]: 0,
        [ETimeKey.HH]: 1
      }
    };
  },
  watch: {
    value: {
      handler(val: string): void {
        if (val === '0' || !val) {
          this.clear();
        } else {
          [this[ETimeKey.HH], this[ETimeKey.MM]] = `${val}`.split('.');
        }
      },
      immediate: true
    }
  },
  computed: {
    preparedValue(): string {
      const val = (+this[ETimeKey.HH] + +this[ETimeKey.MM] / 60).toFixed(2);

      return isNaN(+val) ? '0' : val;
    },
    computedHH: createDataContext.bind(this)(ETimeKey.HH, 4),
    computedMM: createDataContext.bind(this)(ETimeKey.MM)
  },
  methods: {
    save(): void {
      this.$emit('input', this.preparedValue);
      this.close();
    },
    close(): void {
      this.$emit('close');
      this.clear();
    },
    clear(): void {
      this[ETimeKey.HH] = initValue;
      this[ETimeKey.MM] = initValue;
    },
    handleKeydown(e: KeyboardEvent): void {
      ['.', ',', '-', '+'].includes(e.key) && e.preventDefault();
    }
  }
};
