





















































































import * as vr from '@/helpers/validation';
import { ValidationRules } from '@/api/schema';

import PromoCampaignFormMixin from './PromoCampaignFormMixin';

import DurationPicker from '@/components/core/DurationPicker.vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';

export default {
  name: 'PromoCampaignFormCompany',
  mixins: [PromoCampaignFormMixin],

  components: {
    DurationPicker,
    VExtendedDataPicker
  },

  data(): any {
    return {
      showDeadlineDateMenu: false,
      showDurationTimeMenu: false
    };
  },

  computed: {
    enableCustomDeadline(): boolean {
      return (
        this.enabledFields.customPeriodType ===
        this.EFieldTypeKey.CUSTOM_DEADLINE
      );
    },

    enableCustomDuration(): boolean {
      return (
        this.enabledFields.customPeriodType ===
        this.EFieldTypeKey.CUSTOM_DURATION
      );
    },

    rules(): ValidationRules {
      return {
        customDeadlineSelect: this.enableCustomDeadline ? [vr.required] : [],
        customDurationSelect: this.enableCustomDuration ? [vr.required] : [],
        maxNumberUsersInput: this.enabledFields.maxUsersCount
          ? this.positiveNumberRules
          : []
      };
    }
  },

  methods: {
    updateDate(key: string, val: string): void {
      this.updateFormData(key, val);
      this.showDeadlineDateMenu = false;
    }
  }
};
